.imgHeader {
  width: 100%;
}
.img {
  height: 450px;
  width: 400px;
}
.imgTitle {
  width: 80%;
  height: auto;
}
.select {
  /* border-radius: 5px; */
  font-weight: 400;
  text-align: center;
  font-size: 15px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 1px;
  border-bottom-color: black;
  /* border-radius: 0px; */
  /* color: red; */
}

.video-container {
  overflow: hidden;
  position: relative;
  width: 60%;
}

.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: "";
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.centeredRow {
  justify-content: center;
}
