.contanerWordList{
  display: flex;
  justify-content: center;
}

.input {
  margin: 2.5px 5px;
  border-bottom-width: 1px;
}
.checkInput {
  color: white;
  border-radius: 5px;
  /* text-align: center; */
  margin: 2.5px 5px;
  border-bottom-width: 1px;
}

.itemWord{
  width: 40px;
  height: 40px;
  margin: 4px;
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items:center;
  font-size: 20px;
  color: gray;
  font-weight: 600;
  border-style: solid;
  border-width: 0.5px;
  border-color: orange;
  cursor: grabbing;
  user-select: none;
}

/* .itemWord::selection {
  color: orange;
  background: none;
} */

